@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.btn {
  @apply rounded;
  @apply px-5;
  @apply py-2;
  @apply border-b-4;
  @apply border-l-2;
  @apply shadow-lg;
  @apply transition-colors;
  @apply duration-300;
}

.parameter-label {
  @apply font-semibold;
  @apply mb-4;
}

.btn-primary {
  @apply bg-cyan-800;
  @apply border-blue-800;
  @apply text-white;
  @apply hover:bg-cyan-600;
}

.btn-secondary {
  @apply bg-gray-200;
  @apply border-gray-400;
  @apply text-black;
  @apply hover:bg-gray-100;
  @apply disabled:bg-gray-50;
  @apply disabled:border-gray-200;
}

.btn-danger {
  @apply bg-red-400;
  @apply border-red-500;
  @apply text-white;
  @apply hover:bg-red-300;
}

.arrow-btn {
  @apply btn;
  @apply btn-secondary;
  @apply text-center;
  @apply inline-flex;
  @apply items-center;
}

.navbar-link {
  @apply block;
  @apply px-4;
  @apply py-2;
  @apply my-2;
  @apply rounded-md;
  @apply items-center;
  @apply md:ml-5 !important;
}

.active-navbar-link {
  @apply text-cyan-900;
  @apply bg-slate-50;
  @apply font-bold;
}

.not-active-navbar-link {
  @apply text-black;
  @apply hover:text-cyan-900;
}

.logo div {
  @apply bg-cyan-900;
  position: relative;
}

.logo div:before {
  content: "";
  @apply bg-cyan-900;
  position: absolute;
}
